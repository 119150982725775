import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { fetcher } from "services";
import { Provider } from "react-redux";
import { webStore } from "store";
import { SWRConfig } from "swr";
import { ThemeProvider } from "next-themes";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <SWRConfig
        value={{
          fetcher: fetcher,
          onError: (err) => {
            console.error(err);
          },
        }}
      >
        <Provider store={webStore} suppressHydrationWarning={false}>
          <ThemeProvider attribute="class" defaultTheme="light">
            <Component {...pageProps} />
          </ThemeProvider>
        </Provider>
      </SWRConfig>
    </>
  );
}

export default MyApp
