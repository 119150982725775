import { baseValidator } from "utils";
import { axiosObject } from "../setup";

const emailConstraints = {
  value: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your Email ID",
    },
    email: {
      message: "^Please enter a valid Email ID",
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your Password",
    },
  },
};

const mobileConstraints = {
  username: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your mobile number",
    },
    numericality: {
      onlyInteger: true,
      message: "^Please enter a valid Mobile Number",
    },
    format: {
      pattern:
        "^(\\+\\d{2}( )?)?((\\(\\d{3}\\))|\\d{3})[- .]?\\d{3}[- .]?\\d{4}$",
      message: "^Please enter a valid Mobile Number",
    },
  },

  password: {
    presence: {
      allowEmpty: false,
      message: "^Please enter your Password",
    },
  },
};

function register(payload) {
  return new Promise((resolve, reject) => {
    var jsValidationErrors;
    var apiPayload = {};

    if (payload["type"] === "email") {
      jsValidationErrors = baseValidator(payload, emailConstraints);
      apiPayload = {
        type: "email",
        value: payload["value"],
        password: payload["password"],
      };
    }

    if (payload["mode"] === "mobile") {
      payload["username"] = payload["mobile_code"] + payload["mobile_number"];

      jsValidationErrors = baseValidator(payload, mobileConstraints);
      apiPayload = {
        mode: "mobile",
        username: payload["mobile_code"] + payload["mobile_number"],
        password: payload["password"],
      };
    }

    if (jsValidationErrors === true) {
      let uri = "/access/register";

      axiosObject
        .post(uri, apiPayload, { withCredentials: true })
        .then((response) => {
          resolve(response.data);
        })
        .catch((errors) => {
          if (errors.response.status === 422) {
            reject(errors.response.data.errors);
          }
          if (errors.response.status === 401) {
            reject({
              password: "Incorrect Username or Password",
            });
          }
          reject(errors);
        });
    } else {
      reject(jsValidationErrors);
    }
  });
}

export { register };
