import { axiosObject } from "../setup";

// Todo
function destroySession(tokenId) {
  return new Promise((resolve, reject) => {
    let uri = `/access/sessions/${tokenId}`;

    axiosObject
      .delete(uri, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { destroySession };
