import { axiosObject } from "../setup";

function updateProfile(payload) {
  return new Promise((resolve, reject) => {
    let uri = `/access/user`;

    axiosObject
      .post(uri, payload, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { updateProfile };
