import { axiosObject } from "../setup";

// Todo
function getSessions() {
  return new Promise((resolve, reject) => {
    let uri = "/access/sessions";

    axiosObject
      .get(uri, { withCredentials: true })
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { getSessions };
