import { axiosObject } from "../setup";

function usersRedirectForLoginWithGoogle(payload) {
  // console.log("payload-1234", payload);
  return new Promise((resolve, reject) => {
    let uri = `/access/login/google`;

    axiosObject
      .post(uri, payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((errors) => {
        reject(errors);
      });
  });
}

export { usersRedirectForLoginWithGoogle };
